import { Routes } from '@angular/router';
import { authenticatedGuard } from './guards/authenticated.guard';
import { loginGuard } from './guards/login.guard';
import { ErrorsComponent } from './pages/errors/errors.component';
import { LayoutDefaultComponent } from './layouts/layout-default/layout-default.component';
import { HomeComponent } from './pages/home/home.component';
import {  branchGuard } from './guards/branch.guard';
import { cashGuard } from './guards/cash.guard';
import { roleGuard } from './guards/role.guard';

export const routes: Routes = [

  {path: '', component: HomeComponent},

  {path: 'auth', canActivate:[loginGuard],  loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)},

  {path: 'login',canActivate:[loginGuard],   loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)},

  {path: 'reservas',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/schedules/schedules.module').then((m) => m.SchedulesModule)},

  {path: 'patio',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/parking/parking.module').then((m) => m.ParkingModule)},

  {path: 'atendimento',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/attendance/attendance.module').then((m) => m.AttendanceModule)},

  {path: 'checklists',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/checklists/checklists.module').then((m) => m.ChecklistsModule)},

  {path: 'caixa',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/cash/cash.module').then((m) => m.CashModule)},

  {path: 'administracao',canActivate:[authenticatedGuard, branchGuard, cashGuard, roleGuard],   loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule), data:{ roles: ['ADMIN'] }},

  {path: 'conferencias',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/conferences/conferences.module').then((m) => m.ConferencesModule)},

  {path: 'relatorios',canActivate:[authenticatedGuard, branchGuard, cashGuard],   loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule)},

  { path: '', canActivate:[authenticatedGuard, branchGuard, cashGuard],  component: LayoutDefaultComponent, children:[
    { path: 'erro/:code', component: ErrorsComponent },
  ]},

  { path: '**', redirectTo: 'erro/404', pathMatch: 'full' },

];
